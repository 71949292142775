import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import axios from 'axios'
import meta from '../assets/meta.json'
import {
  isAutomatedTest,
  isDev,
  isLocalEnvironment,
} from './functions/functions'

export function catchResponseError(feature, error: any) {
  if (axios.isAxiosError(error)) {
    Sentry.withScope(scope => {
      scope.setTag('feature', feature)
      scope.setTransactionName('UserListView')

      scope.setTag('errorType', 'network')

      scope.setExtra('request', error.request)
      scope.setExtra('response', error.response)
      scope.setExtra('response.data.errors', error.response?.data?.errors)
      scope.setExtra('config', error.config)

      Sentry.captureException(error)
    })
  } else {
    Sentry.withScope(scope => {
      scope.setTag('feature', feature)
      scope.setTag('errorType', 'other')
      Sentry.captureException(error)
    })
  }
}

function isUnhandledRejection(event: Sentry.Event) {
  return (
    event !== undefined &&
    event.exception !== undefined &&
    event.exception.values !== undefined &&
    event.exception.values.length === 1 &&
    event.exception.values[0].type === 'UnhandledRejection' &&
    (event.exception.values[0].value ===
      'Non-Error promise rejection captured with value: ' ||
      event.exception.values[0].value ===
        'Non-Error promise rejection captured with value: undefined')
  )
}

export function initSentry() {
  const isSentryEnabled = !isAutomatedTest() && !isLocalEnvironment()

  if (isSentryEnabled) {
    Sentry.init({
      debug: isDev(),
      dsn: 'https://0b88a4149b82414a88f76dc781d82893@sentry-2.boxine.de/3', // DSN = Data Source Name (= Sentry Project ID)
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: `my-tonies@${meta.commit}`,
      ignoreErrors: [
        // TOC-1551: This error is caused by Google Translate mutating
        // the DOM. The way React works it expects to have full
        // ownership of the DOM without anybody else messing with it.
        // See the ticket TOC-1551 for an in-depth description of what's
        // happening.
        /a\[b\]\.target\.className\.indexOf\(ac\)/,
        /a\[b\]\.target\.className\.indexOf\(bc\)/,
        // The ResizeObserver will print errors when it is not able to
        // deliver all observations within a single animation frame.
        // It has no effect on the correctness of our application. See:
        // https://stackoverflow.com/a/50387233 (TOC-3691)
        /ResizeObserver loop/i,
        // Ignores "IndexSizeError: Failed to execute 'deleteRule' on 'CSSStyleSheet'"
        // error. It is caused by other resources messing with our stylesheets.
        // See: https://github.com/styled-components/styled-components/issues/2382
        /Failed to execute 'deleteRule' on 'CSSStyleSheet'/i,
        // ...Firefox formats the same error as above differently
        // https://sentry.boxine.de/boxine/my-tonies/issues/27758/
        /CSSStyleSheet\.deleteRule(.*)number of rules is only/i,
        // a bug in the iOS version of MS Edge, referencing a non-existant varaible
        // https://stackoverflow.com/a/69576781
        /instantSearchSDKJSBridgeClearHighlight/,
        // TypeError undefined is not an object (evaluating 'window.webkit.messageHandlers')
        // https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
        /window\.webkit\.messageHandlers/,
        // looks like a broken browser extension
        // https://stackoverflow.com/questions/51541174/symbrowser-modifywindowopenwithtarget-browser-error
        /SymBrowser_ModifyAnchorTagWithTarget/,
        // Included in Sentry errors to be ignored sample: https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
        /change_ua/,
        // User Centrics annoyingly throws errors because of some ad blockers
        /\{"cmpLayer":"FIRST_LAYER",/,
      ],
      denyUrls: [
        // ignore errors from bad Safari and Firefox extensions, like
        // https://sentry.boxine.de/boxine/my-tonies/issues/31052/ safari-extension://
        // https://sentry.boxine.de/boxine/my-tonies/issues/31655/ safari-extension:(//
        // https://sentry.boxine.de/boxine/my-tonies/issues/31006/ moz-extension://
        /safari-extension:/,
        /-extension:\(?\/\//,
        // Ignore faulty (likely kaspersky) browser extension which sometimes runs
        // into an infinite loop and floods our sentry instance with events.
        // Those events always have the shape `uuid/main.js`. See:
        // https://sentry.boxine.de/boxine/my-tonies/issues/31060 (TOC-3697)
        /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}\/main\.js/i,
        // Ignore internal PayPal errors rendering errors. Those are not relevant to us
        // and they don't affect payment. See:
        // https://sentry.boxine.de/boxine/my-tonies/?query=is%3Aunresolved+%2Fsdk%2Fjs (TOC-3810)
        /\.paypal\.com/i,
        // Ignore Google Tag Manager errors, like https://sentry.boxine.de/boxine/my-tonies/issues/90319/
        /gtag\/js/,
        // Ignore guuru chat widget errors that are handled in the injected script from guuru by
        // timeout and repeating during initialization of the chat but detected by sentry as unhandled
        // like in: https://sentry-2.boxine.de/organizations/tonies/issues/34931/?query=is%3Aunresolved
        /chat\.guuru\.com\tonies?referer=http/i,
      ],
      beforeSend(event, hint) {
        const error = hint?.originalException

        if (error && typeof error === 'object' && error.message) {
          // "ChunkLoadError" is thrown outside of React rendering, so
          // we need to manually add a fingerprint to it.
          if (/Loading chunk/i.test(error.message)) {
            event.fingerprint = ['chunk-load-error']
          }
          // Group "connection lost" type which don't have a detailed
          // error message, like any other network related error does.
          else if (/^Network Error$/i.test(error.message)) {
            event.fingerprint = ['network-error-connection-lost']
          }
        }

        // Filter out UnhandledRejection errors that have no information
        // See issue: https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
        if (isUnhandledRejection(event)) {
          if (isDev()) {
            console.info('Sentry: Ignoring unhandled rejection', event)
          }

          return null // This will throw an Sentry error (`beforeSend` returned `null`, will not send event.) in debug mode but not in PROD
        }

        return event
      },
      // TODO: Remove if "real" error bubbled
      // This was a hint from a GitHub issue (https://github.com/getsentry/sentry-javascript/issues/3407#issuecomment-822378583)
      // If you disable `history breadrumbs`, the actual error should come through
      integrations: [
        new Sentry.Integrations.Breadcrumbs({ history: false }),
        new BrowserTracing({
          beforeNavigate: tracingContext => {
            return {
              ...tracingContext,
              location: window.location,
            }
          },
        }),
      ],
      tracesSampleRate: 0.2,
    })
  }
}

export function useDisableSentry(isDisabled: boolean): void {
  useEffect(() => {
    if (isDisabled) {
      Sentry.close()
    } else {
      initSentry()
    }
  }, [isDisabled])
}
